import C from "./StandardSidebar.module.css";

import type { ReactNode } from "react";
import { Suspense, useState } from "react";
import type { Action } from "../Action";
import { WButton } from "../button/WButton";
import type { SortFieldInfo, SortState } from "../data/getSortedItems.ts";
import { WSearchField } from "../forms/WSearchField.tsx";
import { ListErrorBoundary } from "./ListErrorBoundary.tsx";
import { SortMenu } from "./SortMenu.tsx";
import { ListBlock, SidebarHeader, SkeletonBlock } from "./StandardSidebar";
import { useStoredSort } from "./useStoredSort.ts";

export interface SidebarListState extends SortState {
    queryString: string;
}

export type SidebarListId = "access" | "network" | "compute" | "storage" | "billing" | "services";

export interface SidebarListProps {
    data: (props: SidebarListState) => ReactNode;
    title: string;
    sortFields: SortFieldInfo[];
    actionButton: {
        title: string;
        titleShort?: string;
        action: Action;
    };
    listId: SidebarListId;
}

export function SidebarList({
    title,
    sortFields,
    data,
    listId,
    actionButton: { action, title: titleLong, titleShort = "New" },
}: SidebarListProps) {
    const [queryString, setQueryString] = useState("");
    const { sortId, setSortId, sortAsc, setSortAsc } = useStoredSort(listId, sortFields);

    return (
        <>
            <SidebarHeader
                title={title}
                searchField={<WSearchField value={queryString} onChange={setQueryString} />}
                sortMenu={
                    <SortMenu
                        sortFields={sortFields}
                        sortId={sortId}
                        onSortId={setSortId}
                        sortAsc={sortAsc}
                        onSortAsc={setSortAsc}
                    />
                }
            >
                <WButton inSidebar label={titleShort} color="primary" size="bar" action={action} />
            </SidebarHeader>

            <ListBlock>
                <ListErrorBoundary>
                    <Suspense fallback={<SkeletonBlock />}>{data({ sortId, sortAsc, queryString })}</Suspense>
                </ListErrorBoundary>

                <div className="p-2">
                    <WButton
                        className={C.NewButton}
                        label={titleLong}
                        icon="jp-icon-add"
                        color="primary"
                        variant="dashed"
                        size="lg"
                        action={action}
                    />
                </div>
            </ListBlock>
        </>
    );
}
